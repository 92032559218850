import { SystemEvent, SystemSlotEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { isObject, once, timelimitPromise } from '@mbrtargeting/metatag-utils';
import { Phase } from '../../../interfaces/constants.js';
import { PhaseMetadata, getAllPhaseMetadata } from '../../../loader/decorators/on-phase.js';
import { Registry } from '../../../loader/di/registry.js';
import { triggerEvent, waitForEventPromise } from '../../../loader/essentials/events.js';
import { markSdg, measurePerformance } from '../../../utils/performance.js';
import { StateMachine } from '../../state-machine.js';

export enum PhaseAction {
    WAIT_FOR_CONFIG = 'WAIT_FOR_CONFIG',
    GETTING_READY = 'GETTING_READY',
    START_TARGETING = 'START_TARGETING',
    SERVE_ADS = 'SERVE_ADS',
    START_TRACKING = 'START_TRACKING',
}


export class PhaseController extends StateMachine<Phase, PhaseAction> {

    constructor() {
        super({
            logPrefix: 'Phase',
            initialState: Phase.INIT,
            measure: true,
            transitions: [
                { from: Phase.INIT, action: PhaseAction.WAIT_FOR_CONFIG, to: Phase.CONFIGURED },
                { from: Phase.CONFIGURED, action: PhaseAction.GETTING_READY, to: Phase.READY },
                { from: Phase.READY, action: PhaseAction.START_TARGETING, to: Phase.TARGETING },
                { from: Phase.TARGETING, action: PhaseAction.SERVE_ADS, to: Phase.DELIVERY },
                { from: Phase.DELIVERY, action: PhaseAction.START_TRACKING, to: Phase.TRACKING },
            ],
            initialContext: {},
        });
        this.onAction(PhaseAction.WAIT_FOR_CONFIG, async () => {
            await waitForEventPromise(SystemEvent.SDG_CONFIG_FILE_AVAILABLE);
        });
        this.onState(Phase.CONFIGURED, async () => {
            this.dispatch(PhaseAction.GETTING_READY);
        });
        this.onAction(PhaseAction.GETTING_READY, async () => waitForEventPromise(SystemEvent.SDG_CORE_FILE_LOADED));
        this.onAction(PhaseAction.GETTING_READY, async () => {
            await waitForEventPromise(SystemEvent.SDG_CMP_CACHED_CONSENT_AVAILABLE);
        }, { name: `waitForConsent`, measure: true, });

        /**
         * Phase READY
         */
        this.onState(Phase.READY, async () => {
            await Promise.race([
                waitForEventPromise(SystemEvent.SDG_DOM_CONTENT_LOADED),
                waitForEventPromise(SystemEvent.SDG_SLOTS_FINALIZED)
            ]);
            this.dispatch(PhaseAction.START_TARGETING);
        }, { name: `waitForSlotFinalize`, measure: true });
        this.onEnter(Phase.TARGETING, async () => {
            markSdg(`targeting-phase-started_mt`);
        });
        this.onState(Phase.TARGETING, async () => {
            await this.runPhaseFunctions(Phase.TARGETING, 150);
            this.dispatch(PhaseAction.SERVE_ADS);
        });
        this.onLeave(Phase.TARGETING, async () => {
            markSdg(`targeting-phase-ended_mt`);
        });
        const waitForFirstSlotLoadedPromise = new Promise<void>(resolve => {
            window.addEventListener(SystemSlotEvent.SDG_SLOT_DONE, (event) => once(resolve)(), { once: true }); // using once function additionally as opera mini does not support once option
        });
        this.onState(Phase.DELIVERY, async () => {
            triggerEvent(SystemEvent.DELIVERY_PHASE_STARTED, {});
            await this.runPhaseFunctions(Phase.DELIVERY);
            await timelimitPromise(waitForFirstSlotLoadedPromise, 5000);
            this.dispatch(PhaseAction.START_TRACKING);
        });
        this.onState(Phase.TRACKING, async () => {
            triggerEvent(SystemEvent.TRACKING_PHASE_STARTED, {});
            await this.runPhaseFunctions(Phase.TRACKING);
            measurePerformance('Phase-TRACKING').end()
        });

        this.dispatch(PhaseAction.WAIT_FOR_CONFIG);
    }

    private async runPhaseFunctions(phase: Phase, timeLimit: number = -1): Promise<void> {
        const phaseMetadata = this.getPhaseMetadata().filter(metadata => metadata.phase === phase);
        await timelimitPromise(Promise.allSettled(
            phaseMetadata.map(async ({ description, invoke }) => {
                const measurement = measurePerformance(`Phase-${phase}-${description}`, { log: true });
                measurement.start()
                await invoke();
                measurement.end()
            })
        ), timeLimit);
    }

    private getPhaseMetadata() {
        type Mapper<I, O> = (input: I) => O;
        type Reducer<O, I> = (aggregator: O, value: I) => O;
        type ExtendedPhaseMetadata = PhaseMetadata & { invoke: Function };

        // reduces the metadata of multiple objects into a flat array`
        const reduceObjectsMetadata: Reducer<ExtendedPhaseMetadata[], Object> = (aggregator1, object) => {
            // get all phase metadata for a given object; the resulting `MetadataMap` contains an array of `PhaseMetadata` per `methodName`
            const metadataPerMethod: Record<string | symbol, PhaseMetadata[]> = getAllPhaseMetadata(object) || {};
            // reduces the metadata of all methods of an object into a flat array
            const reduceMethodMetadata: Reducer<ExtendedPhaseMetadata[], [string, PhaseMetadata[]]> = (aggregator2, [methodName, metadataArray]) => {
                // the mapper adds additional properties to the metadata
                const mapper: Mapper<PhaseMetadata, ExtendedPhaseMetadata> = (metadata) => ({
                    description: methodName,
                    ...metadata,
                    invoke: ((object as any)[methodName] as Function).bind(object),
                });
                // concatenate the method metadata as interim result
                return [...aggregator2, ...metadataArray.map(mapper)];
            };
            // concatenate the method metadata of all objects
            return [...aggregator1, ...Object.entries(metadataPerMethod).reduce(reduceMethodMetadata, [])];
        };

        return [...Registry.entries()].map(([/*key*/, element]) => element).filter(isObject).reduce(reduceObjectsMetadata, []);
    }
}
