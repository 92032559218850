import { StorageInterface } from './storage-interface.js';

export class MemoryStorage implements StorageInterface {

    constructor(
        protected readonly storage: Record<string, string> = {},
    ) { }

    private keys(): string[] {
        return Object.keys(this.storage);
    }

    public get length(): number {
        return this.keys().length;
    };

    public clear(): void {
        this.keys().forEach(key => this.removeItem(key));
    }

    public getItem(key: string): string | null {
        return this.storage[key] || null;
    }

    public key(index: number): string | null {
        return this.keys()[index] || null;
    }

    public removeItem(key: string): void {
        delete this.storage[key];
    }

    public setItem(key: string, value: string): void {
        this.storage[key] = value;
    }
}
