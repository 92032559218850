import type { AdhesionUnitWatcherInterface, AdvertisingApi, ContentObjectWatcherInterface, PublisherAPI } from '@mbrtargeting/metatag-shared-types';
import { InjectionToken } from '../loader/di/injection-token.js';
import type { AdslotControllerInterface } from './modules/adslot-controller/adslot-controller-interface.js';
import type { CreativeSource } from './modules/adslot-controller/creative-source.js';
import type { FeatureController } from './modules/feature-controller/feature-controller.js';
import type { FormatController } from './modules/format-controller/format-controller.js';
import type { GenericTargeting } from './modules/generic-targeting/generic-targeting.js';
import type { GlobalSettings } from './modules/global-settings/global-settings.js';
import type { GlobalTargeting } from './modules/global-targeting/global-targeting.js';
import type { IdStore } from './modules/idstore/idstore.js';
import type { StorageHelper } from './modules/storage/storage-helper.js';
import type { VastTagGenerator } from './modules/video/vast-tag-generator.js';

export const ADHESION_UNIT_WATCHER = new InjectionToken<AdhesionUnitWatcherInterface>('AdhesionUnitWatcher');

export const ADVERTISING_API = new InjectionToken<AdvertisingApi>('AdvertisingApi');

export const ADSLOT_CONTROLLER = new InjectionToken<AdslotControllerInterface>('AdslotController');

export const AUDIENCE_PROJECT = new InjectionToken<never>('AudienceProject');

export const CONTENT_OBJECT_WATCHER = new InjectionToken<ContentObjectWatcherInterface>('ContentObjectWatcher');

export const COUNTRY_CODE_TARGETING = new InjectionToken<never>('CountryCodeTargeting');

export const CSS_INJECTOR = new InjectionToken<never>('CssInjector');

export const DOUBLE_VERIFY_TRACKING_AD_SLOT_ADDON = new InjectionToken<never>('DoubleVerifyTrackingAdSlotAddon');

export const FORMAT_CONTROLLER = new InjectionToken<FormatController>('FormatController');

export const FULL_PUBLISHER_API = new InjectionToken<PublisherAPI>('FullPublisherAPI');

export const GENERIC_TARGETING = new InjectionToken<GenericTargeting>('GenericTargeting');

export const GLOBAL_SETTINGS = new InjectionToken<GlobalSettings>('GlobalSettings');

export const GLOBAL_TARGETING = new InjectionToken<GlobalTargeting>('GlobalTargeting');

export const ID_STORE = new InjectionToken<IdStore>('IdStore');

export const LOAD_AD_LIBRARY = new InjectionToken<never>('LoadAdLibrary');

export const MBR_ID = new InjectionToken<never>('MbrId');

export const META_KEYWORDS_COLLECTOR = new InjectionToken<never>('MetaKeywordsCollector');

export const CANONICAL_URL_COLLECTOR = new InjectionToken<never>('CanonicalUrlCollector');

export const NATIVE_BACKFILL = new InjectionToken<CreativeSource>('NativeBackfill');

export const NET_ID = new InjectionToken<never>('NetId');

export const NET_ID_TRACKING = new InjectionToken<never>('NetIdTracking');

export const PHASE_CONTROLLER = new InjectionToken<never>('PhaseController');

export const PUBLIC_INFOTOOL_API = new InjectionToken<never>('PublicInfotoolAPI');

export const RECOVER_PREBID_IDS = new InjectionToken<never>('RecoverPreBidIds');

export const RINGIER_GAM_ADSERVER = new InjectionToken<CreativeSource>('RingierGamAdserver');

export const SPEED_CURVE = new InjectionToken<never>('SpeedCurve');

export const STORAGE_HELPER = new InjectionToken<StorageHelper>('StorageHelper');

export const STROEER_GAM_ADSERVER = new InjectionToken<CreativeSource>('StroeerGamAdserver');

export const STROEER_SSP = new InjectionToken<CreativeSource>('StroeerSsp');

export const STROEER_SPONSORSHIP = new InjectionToken<CreativeSource>('StroeerSponsorship');

export const UTIQ = new InjectionToken<never>('Utiq');

export const UTIQ_TRACKING = new InjectionToken<never>('UtiqTracking');

export const VAST_TAG_GENERATOR = new InjectionToken<VastTagGenerator>('VastTagGenerator');

export const YIELDLOVE = new InjectionToken<CreativeSource>('Yieldlove');

export const FEATURE_CONTROLLER = new InjectionToken<FeatureController>('FeatureController');
