import { createMutationObservable, isHTMLElement, Observable } from '@mbrtargeting/metatag-utils';

/**
 * Creates an `Observable` which emits an `HTMLElement` once an element with given id exists on the dom.
 *
 * @param id the id of the element to search or wait for
 * @param doc the document to search in
 * @returns an `Observable` of the element
 */
export const waitForElement$ = (id: string, doc: Document = document) => new Observable<HTMLElement>(subscriber => {
    // initially check if the element already exists
    const element = doc.getElementById(id);
    if (element) {
        subscriber.next(element);
        subscriber.complete();
        return;
    }
    // monitor the dom for added elements with the given id
    createMutationObservable(doc.documentElement, { childList: true, attributes: true, attributeFilter: ['id'], subtree: true })
        .flatMap(mutations => mutations)
        .subscribe(({ type, addedNodes, target }) => {
            if (type === 'childList') {
                for (const node of Array.from(addedNodes)) {
                    if (isHTMLElement(node) && node.id === id) {
                        subscriber.next(node);
                        subscriber.complete();
                        break;
                    }
                }
            } else if (type === 'attributes' && isHTMLElement(target) && target.id === id) {
                subscriber.next(target);
                subscriber.complete();
            }
        }, { signal: subscriber.signal });
});
