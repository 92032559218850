// https://developerzone.netid.dev/latest/cmp/browser-based/

/**
 * All possible netid status codes in a response.
 */
export enum NetIdReadStatusCode {
    /**
     * Call successful.
     * Permissions found and returned.
     *
     * HTTP status code: 200 OK
     */
    PERMISSIONS_FOUND = 'PERMISSIONS_FOUND',
    /**
     * Call successful.
     * netID user is authenticated.
     * No permission (privacy status) found for the given tapp_id.
     * No subject_identifiers returned.
     *
     * HTTP status code: 200 OK
     */
    PERMISSIONS_NOT_FOUND = 'PERMISSIONS_NOT_FOUND',
    /**
     * No tpid_sec cookie in request available.
     * No authenticated netID user.
     *
     * HTTP status code: 400 BAD REQUEST
     */
    NO_TPID = 'NO_TPID',
    /**
     * Token in cookie is expired or invalid.
     *
     * HTTP status code: 400 BAD REQUEST
     */
    TOKEN_ERROR = 'TOKEN_ERROR',
    /**
     * TAPP tapp_id is not allowed, e.g. origin not eligible.
     *
     * HTTP status code: 403 FORBIDDEN
     */
    TAPP_NOT_ALLOWED = 'TAPP_NOT_ALLOWED',
    /**
     * Account does not exist anymore.
     *
     * HTTP status code: 410 GONE
     */
    TPID_EXISTENCE_ERROR = 'TPID_EXISTENCE_ERROR',
}

/**
 * with type:
 *   IDCONSENT - If consent "IDCONSENT" is given, value is 'VALID' and users netID identifier (tpid) is returned. If consent "IDCONSENT" is revoked, value is 'INVALID'. Otherwise null.
 *   IAB_TC_STRING - TC string stored for this tpid for this respective netID Partner (TAPP).
 */
export interface NetIdPrivacySettingsEntry {
    type?: string;
    status?: string;
    value?: string;
    changed_at: string;
}

/**
 * A response returned by the netid service endpoint.
 */
export interface NetIdReadResponse {
    /**
     * The status code returned by netid endpoint.
     */
    status_code: NetIdReadStatusCode;

    subject_identifiers?: {
        /**
         * The netid user identificator (userId) if we are allowed to use it, undefined otherwise.
         *
         */
        tpid?: string;
        /**
         * Partner specific Sync-ID of the netID user.
         * Only returned if any "netid_privacy_settings" are present in the permission
         * center at the time of the API call.
         */
        sync_id?: string;
    }

    netid_privacy_settings?: NetIdPrivacySettingsEntry[];
}

export interface NetIdWriteRequest {
    /**
     * Is indicating the status of the consent "IDCONSENT".
     *   - VALID = Consent is given.
     *   - INVALID = Consent is revoked
     */
    idconsent: 'VALID' | 'INVALID';
    /**
     * TC String which should be stored/updated for this netID user
     * for this respective netID partner (TAPP)
     */
    iab_tc_string?: string;
}

export enum NetIdWriteStatusCode {
    /**
     * No tpid_sec cookie in request available.
     *
     * HTTP status code: 400 BAD REQUEST
     */
    NO_TPID = 'NO_TPID',
    /**
     * Token in cookie is expired or invalid.
     *
     * HTTP status code: 400 BAD REQUEST
     */
    TOKEN_ERROR = 'TOKEN_ERROR',
    /**
     * Parameters are missing.
     * At least one permission must be set.
     *
     * HTTP status code: 400 BAD REQUEST
     */
    NO_PERMISSION = 'NO_PERMISSION',
    /**
     * Invalid JSON body, parse error.
     *
     * HTTP status code: 400 BAD REQUEST
     */
    JSON_PARSE_ERROR = 'JSON_PARSE_ERROR',
    /**
     * Required request body is missing.
     *
     * HTTP status code: 400 BAD REQUEST
     */
    NO_REQUEST_BODY = 'NO_REQUEST_BODY',
    /**
     * Syntactic or semantic error in a permission(e.g.invalid TC String).
     *
     * HTTP status code: 400 BAD REQUEST
     */
    PERMISSION_PARAMETERS_ERROR = 'PERMISSION_PARAMETERS_ERROR',
    /**
     * Missing parameters in request / Requesting TAPP isn't active/Origin not eligible
     *
     * HTTP status code: 403 FORBIDDEN
     */
    TAPP_NOT_ALLOWED = 'TAPP_NOT_ALLOWED',
    /**
     * Account does not exist anymore.
     *
     * HTTP status code: 410 GONE
     */
    TPID_EXISTENCE_ERROR = 'TPID_EXISTENCE_ERROR',
}

export interface NetIdWriteResponse {
    status_code?: NetIdWriteStatusCode;
    subject_identifiers?: {
        /**
         * Users netID identifier (tpid).
         * Only returned if consent "idconsent" is VALID, the tpid is known
         * (i.e. user is already authenticated on the device) and status "PERMISSIONS_FOUND".
         * Otherwise null.
         *
         */
        tpid?: string;
        /**
         * Partner specific sync-id of the netID user.
         */
        sync_id?: string;
    }
}

/**
 * Options to pass to the netId layer script
 */
export interface NetIdLayerOptions {
    privacyUrl?: string;
    logoUrl?: string;
    informalText?: boolean;
    onDecline: () => void;
    onAccept: () => void;
    onDeny: () => void;
}
