/**
 * A base64url reverse dictionary.
 *
 * { A: 1, B: 2, ...}
 */
const DICT = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_'.split('').reduce<Record<string, number>>((acc, char, index) => ({ ...acc, [char]: index }), {});

/**
 * Converts a base64url encoded string into its bitfield representation.
 *
 * @param input The base64url encoded string to convert.
 * @returns The bitfield representation of the base64url encoded string.
 * @throws Will throw an error if the input string contains invalid base64url characters.
 */
const base64urlAsBitField = (input: string, bitlen: number = 8) => input.split('').reduce((acc, char) => acc + DICT[char].toString(2).padStart(bitlen, '0'), '');

/**
 * Converts a substring of bits to a number.
 *
 * @param bits The bitfield string.
 * @param position The starting position in the bitfield string.
 * @param bitlen The length of the bitfield substring to use.
 * @returns The number representation of the specified bitfield substring.
 */
const tcNumber = (bits: string, position: number, bitlen: number) => parseInt(bits.substring(position, position + bitlen), 2);

/**
 * Converts a substring of bits to a Date object.
 * The tcString uses 36 bits to encode a date (see tcf2 spec).
 *
 * @param bits - The bitfield string.
 * @param position - The starting position in the bitfield string.
 * @returns A Date object representing the time encoded in the bitfield substring.
 */
const tcDate = (bits: string, position: number, bitlen: number) => new Date(tcNumber(bits, position, bitlen) * 100);

/**
 * Extracts some data from the tcString.
 *
 * @param tcString a valid tcString
 * @returns some extracted fields
 */
export const tcStringInfo = (tcString: string) => {
    // the tcString consists of different segments separated by dot, we need only the first segment
    const [coreSegment] = tcString.split('.');
    // decoding the base64url based tcString using a field length of 6 bit (see tcf2 spec)
    const bits = base64urlAsBitField(coreSegment, 6);
    // extracting some selected fields
    return {
        // the created field starts a bit 6 with an lenght of 36 bits (see tcf2 spec)
        created: tcDate(bits, 6, 36),
        // the lastUpdated field starts a bit 42 with an lenght of 36 bits (see tcf2 spec)
        lastUpdated: tcDate(bits, 42, 36),
    };
}
