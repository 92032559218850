import { NetIdModuleSettings } from '@mbrtargeting/metatag-config-types';
import { isFunction, isString, loadScript } from '@mbrtargeting/metatag-utils';
import { sendCorsRequest } from '../../../utils/send-cors-request.js';
import { NetIdLayerOptions, NetIdPrivacySettingsEntry, NetIdReadResponse, NetIdReadStatusCode, NetIdWriteRequest, NetIdWriteResponse } from './netid-interfaces.js';

declare var window: Window & yieldlove.Window & {
    netidPopup?: {
        cmd: Function[];
        show?: (options: NetIdLayerOptions) => void;
    },
};

export enum NetIDStatus {
    UNKNOWN_USER,
    NETID_USER,
    ERROR,
}

export interface ReadNetIDUserStatusOptions {
    /** netid publisher id */
    tappId: string;
}

export type ReadNetIDUserStatusResult = {
    status: NetIDStatus;
    userId?: string;
    history?: NetIdPrivacySettingsEntry[];
}

/**
 * Check if the user is a known netID user
 */
export const readNetIDUserStatus = async ({ tappId }: ReadNetIDUserStatusOptions) => new Promise<ReadNetIDUserStatusResult>((resolve) => {
    sendCorsRequest<NetIdReadResponse>({
        method: 'GET',
        src: `https://einwilligungsspeicher.netid.de/netid-user-status?q.tapp_id.eq=${tappId}`,
        onload: (response) => {
            switch (response?.status_code) {
                case NetIdReadStatusCode.PERMISSIONS_FOUND:
                case NetIdReadStatusCode.PERMISSIONS_NOT_FOUND:
                case NetIdReadStatusCode.TOKEN_ERROR:
                case NetIdReadStatusCode.TAPP_NOT_ALLOWED:
                    resolve({
                        status: NetIDStatus.NETID_USER,
                        userId: response.subject_identifiers?.tpid,
                        history: response.netid_privacy_settings,
                    });
                    break;

                case NetIdReadStatusCode.TPID_EXISTENCE_ERROR:
                case NetIdReadStatusCode.NO_TPID: // no netID user known
                default: // something went wrong
                    resolve({
                        status: NetIDStatus.UNKNOWN_USER,
                    });
            }
        },
        onerror: () => resolve({
            status: NetIDStatus.ERROR,
        }),
        withCredentials: true,
        ignoreStatus: true,
        accept: 'application/vnd.netid.permission-center.netid-user-status-v1+json',
        async: true
    });
});

export interface WriteNetIDPermissionsOptions {
    /** true if user gave consent */
    consent: boolean;
    /** netid publisher id */
    tappId: string;
}

export type WriteNetIDPermissionsResult = {
    status: NetIDStatus;
    userId?: string;
}

/**
 * Write consent to netID.
 *
 * @param param0 WriteOptions
 */
export const writeNetIDPermissions = ({ consent, tappId }: WriteNetIDPermissionsOptions) => new Promise<WriteNetIDPermissionsResult>((resolve) => {
    const payload: NetIdWriteRequest = {
        idconsent: consent ? 'VALID' : 'INVALID',
    };
    sendCorsRequest<NetIdWriteResponse>({
        method: 'POST',
        src: `https://einwilligen.netid.de/netid-permissions?q.tapp_id.eq=${tappId}`,
        onload: (response, statusCode) => {
            if (statusCode === 201) {
                resolve({ status: NetIDStatus.NETID_USER, userId: response.subject_identifiers?.tpid });
            } else {
                resolve({ status: NetIDStatus.ERROR });
            }
        },
        onerror: () => resolve({ status: NetIDStatus.ERROR }),
        withCredentials: true,
        async: true,
        contentType: 'application/vnd.netid.permission-center.netid-permissions-v2+json',
        accept: 'application/vnd.netid.permission-center.netid-subject-status-v1+json',
        ignoreStatus: true,
        body: JSON.stringify(payload),
    });
});

export enum ShowLayerResult {
    ACCEPT,
    DENY,
    DECLINE,
}

/**
 * Show netid consenter layer and ask netID user for consent
 */
export const showLayer = async ({ consenterUrl, privacyUrl, logoUrl, informalText }: Partial<NetIdModuleSettings>) => new Promise<ShowLayerResult>((resolve, reject) => {
    if (!isString(consenterUrl) && !consenterUrl) {
        return reject('consenterUrl not set');
    }

    window.netidPopup = window.netidPopup || { cmd: [] };
    window.netidPopup.cmd.push(() => {
        window.netidPopup!.show!({
            privacyUrl,
            logoUrl,
            informalText,
            onDecline: () => resolve(ShowLayerResult.DECLINE),
            onAccept: () => resolve(ShowLayerResult.ACCEPT),
            onDeny: () => resolve(ShowLayerResult.DENY),
        });
    });

    loadScript({
        src: consenterUrl,
        node: document.head,
    });
});

export interface SendNetIdToYieldloveOptions {
    userId: string;
}

export const sendNetIdToYieldlove = async ({ userId }: SendNetIdToYieldloveOptions) => new Promise<void>((resolve) => {
    // if userId is undefined or empty string, do not send it
    if (!userId) return resolve();

    window.yieldlove_cmd ||= [];
    window.yieldlove_cmd.push(() => {
        const { YLHH, pbjsYLHH } = window;
        const userIdObj = { name: 'netId', value: { netId: userId } };
        if (isFunction(YLHH?.bidder?.updateUserId)) { // requires https://github.com/yieldlove/Prebid.js/pull/1207 to be released
            YLHH.bidder.updateUserId(userIdObj);
        } else { // fallback to prebid standard way to update a id
            pbjsYLHH.mergeConfig({ userSync: { userIds: [userIdObj] } });
            pbjsYLHH.refreshUserIds();
        }
        resolve();
    });
});
