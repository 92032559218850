import { SystemEvent } from '@mbrtargeting/metatag-shared-types';
import { debounce } from '@mbrtargeting/metatag-utils';
import { inject, injectionTarget } from '../../../loader/decorators/inject.js';
import { ConfigResolver } from '../../../loader/essentials/config-resolver.js';
import { selectModuleSettings } from '../../../loader/essentials/config-selector.js';
import { CONFIG_RESOLVER } from '../../../loader/token.js';
import { sessionId$ } from '../../observables/session-id.js';

@injectionTarget()
export class NetIdTracking {

    @inject(CONFIG_RESOLVER) #configResolver!: ConfigResolver;

    #bitMask: number = 0;

    #bitMap = {
        [SystemEvent.NETID_HAS_NO_ACCOUNT]: 1, // 0000000001
        [SystemEvent.NETID_HAS_ACCOUNT]: 2, // 0000000010
        [SystemEvent.NETID_RESPONDED_USER_UNKNOWN]: 4, // 0000000100
        [SystemEvent.NETID_RESPONDED_USER_KNOWN]: 8, // 0000001000
        [SystemEvent.NETID_RESPONDED_USERVALID]: 16, // 0000010000
        [SystemEvent.NETID_CHECK_USER]: 32, // 0000100000
        [SystemEvent.NETID_SHOW_LAYER]: 64, // 0001000000
        [SystemEvent.NETID_RESPONDED_POSITIVE]: 128, // 0010000000
        [SystemEvent.NETID_RESPONDED_NEGATIVE]: 256, // 0100000000
        [SystemEvent.NETID_RESPONDED_ERROR]: 512, // 1000000000
    }

    constructor() {
        const sendDebounced = debounce(() => this.send(), 500);

        for (const [type, value] of Object.entries(this.#bitMap)) {
            window.addEventListener(type, () => {
                this.#bitMask |= value;
                sendDebounced();
            });
        }
    }

    private send() {
        try {
            const { enableBeacons = false } = this.#configResolver.get(selectModuleSettings('NETID')) || {};
            enableBeacons && window.navigator.sendBeacon(`https://ih.adscale.de/netid?netIdEvent=${this.#bitMask}&groupId=${sessionId$.value}`);
        } catch (e) {
            // ignore
        }
    }
}
