import { SystemEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { generateUUID, loadScript, once } from '@mbrtargeting/metatag-utils';
import { inject, injectionTarget } from '../../../loader/decorators/inject.js';
import { onPhase, Phase } from '../../../loader/decorators/on-phase.js';
import { ConfigResolver } from '../../../loader/essentials/config-resolver.js';
import { selectModuleSettings } from '../../../loader/essentials/config-selector.js';
import { triggerEvent } from '../../../loader/essentials/events.js';
import { logger } from '../../../loader/essentials/logger.js';
import { ConsentCache } from '../../../loader/modules/consent-cache.js';
import { CONFIG_RESOLVER, CONSENT_CACHE } from '../../../loader/token.js';
import { evaluateConsent } from '../../../utils/consent-helper.js';
import { sendCorsRequestPromise } from '../../../utils/send-cors-request.js';
import { tcStringInfo } from '../../../utils/tcstring-helper.js';
import { ID_STORE } from '../../token.js';
import { waitForElement$ } from '../../utils/wait-for-element.js';
import { IdStore } from '../idstore/idstore.js';
import { MnoResponse, MnoStatus } from './utiq-interfaces.js';

const log = logger.logGroup({ prefix: 'UTIQ' });

declare const window: Window & utiq.Window;

const mapMnoStatusToEvent: Record<MnoStatus, SystemEvent> = {
    [MnoStatus.NOT_FOUND]: SystemEvent.UTIQ_USER_NOT_ELIGIBLE,
    [MnoStatus.NOT_ENABLED]: SystemEvent.UTIQ_USER_ELIGIBLE_NOT_DOMAIN,
    [MnoStatus.OK]: SystemEvent.UTIQ_USER_ELIGIBLE,
}

/**
 * UTIQ id provider (formerly known as Trustpid)
 *
 * consenthub:
 *      https://consenthub.utiq.com/
 *
 * reset state for development:
 *      window.Utiq.API.handleDataClear();
 *
 * enable verbose logging:
 *      window.Utiq.API.setVerboseLoggingLevel();
 */
@injectionTarget()
export class Utiq {

    @inject(CONFIG_RESOLVER) #configResolver!: ConfigResolver;
    @inject(CONSENT_CACHE) #consentCache!: ConsentCache;
    @inject(ID_STORE) #idStore!: IdStore;

    private static readonly ADTECH_ID_NAME = 'utiq';
    private static readonly MARTECH_ID_NAME = 'utiq_mtid';

    @onPhase({ phase: Phase.TARGETING })
    async requestUtiqIds() {
        const {
            active = false,
            iabVendorId = 0,
            customUtiqHost = 'https://frontend.prod.utiq-aws.net',
            customizationOptions, // DEFAULT: undefined - use utiq defaults
            delayLayerTime, // DEFAULT: undefined - do not delay the layer
        } = this.#configResolver.get(selectModuleSettings('UTIQ')) || {};

        if (!active) return log.debug('not active');

        const tcData = await this.#consentCache.getTcDataPromise();

        const allowed = evaluateConsent(tcData, [
            { consentedPurposes: [1], consentedVendors: [iabVendorId] },
            { gdprApplies: false },
        ]);

        if (!allowed) return log.debug('no consent');

        this.#idStore.getIdDataPromise(Utiq.ADTECH_ID_NAME).then(idData => {
            triggerEvent(idData?.value ? SystemEvent.UTIQ_ID_PRESENT : SystemEvent.UTIQ_ID_ABSENT, {});
        });

        const transactionId = generateUUID();

        // check if the user had recently a cmp consent layer; we should have some time before showing the utiq layer
        const { lastUpdated = new Date() } = tcData && tcStringInfo(tcData.tcString) || {};
        const skipLayer: boolean = (!!delayLayerTime && ((Date.now() - lastUpdated.getTime()) < delayLayerTime));

        const triggerConsentEventOnce = once((isConsentGranted: boolean) => {
            triggerEvent(isConsentGranted ? SystemEvent.UTIQ_CONSENT_TRUE : SystemEvent.UTIQ_CONSENT_FALSE, {});
        });

        window.Utiq = {
            transactionId,
            config: {
                customUtiqHost,
                customizationOptions,
                CMP: skipLayer ? 'none' : undefined,
                listeners: {
                    onEligibilityChecked: ({ isEligible }) => {
                        log.debug(`onEligibilityChecked - isEligible=${isEligible}`);
                        setTimeout(() => triggerConsentEventOnce(false), 1500); // dirty hack for missing onIdsUnavailable event
                    },
                    onInitialised: () => {
                        log.debug(`onInitialised - skipLayer=%s`, [skipLayer, window.Utiq.config]);
                    },
                    onConsentChanging: ({ isConsentGranted }) => {
                        log.debug(`onConsentChanging - isConsentGranted=${isConsentGranted}`);
                        triggerConsentEventOnce(isConsentGranted);
                    },
                    onConsentUpdateFinished: ({ isConsentGranted }) => {
                        log.debug(`onConsentUpdateFinished - isConsentGranted=${isConsentGranted}`);
                        triggerConsentEventOnce(isConsentGranted);
                    },
                    onConsentManagerStatusChanged: ({ status }) => {
                        log.debug(`onConsentManagerStatusChanged - status=${status}`);
                        if (status === 'utiq_popup_rejected') triggerEvent(SystemEvent.UTIQ_LAYER_REJECT, {});
                        if (status === 'utiq_popup_accepted') triggerEvent(SystemEvent.UTIQ_LAYER_ACCEPT, {});
                        if (status === 'utiq_popup_shown') triggerEvent(SystemEvent.UTIQ_LAYER_SHOW, {});
                    },
                    onIdsAvailable: ({ atid, mtid }) => {
                        log.debug(`onIdsAvailable - got ids atid=${atid}, mtid=${mtid}`);
                        this.#idStore.setIdPromise(Utiq.ADTECH_ID_NAME, atid);
                        this.#idStore.setIdPromise(Utiq.MARTECH_ID_NAME, mtid);
                        triggerConsentEventOnce(true);
                    },
                    onFlowCompleted: () => {
                        log.debug(`onFlowCompleted`);
                    },
                }
            },
        }

        // if an element with id 'utiq-manage-page' exists or is added, load the utiqManagePage.js
        waitForElement$('utiq-manage-page')
            .find(e => !!e)
            .then(() => loadScript({
                src: `${customUtiqHost}/utiqManagePage.js`,
                node: document.head,
                onload: () => log.debug('utiqManagePage.js loaded'),
                onerror: () => log.error('utiqManagePage.js failed to load'),
            }));

        // To limit the consent notification to only eligible users, utiq is using the dedicated endpoint: /op/idconnect/mno-precheck
        const { status = MnoStatus.NOT_FOUND } = await sendCorsRequestPromise<MnoResponse>({
            method: 'GET',
            src: `${customUtiqHost}/op/idconnect/mno-precheck?type=preload&transactionId=${transactionId}`,
            withCredentials: true,
            accept: 'application/json',
        });

        triggerEvent(mapMnoStatusToEvent[status], {});

        // if a non-eligible user, we can abort
        if (status !== MnoStatus.OK) return log.debug('non-eligible user');

        log.debug('utiqLoader.js loading...');
        // load the utiq javascript file
        loadScript({
            src: `${customUtiqHost}/utiqLoader.js`,
            node: document.head,
            onload: () => log.debug('utiqLoader.js loaded'),
            onerror: () => log.error('utiqLoader.js failed to load'),
        });
    }
}
