/**
 * The utiq status types for an user.
 */
export enum MnoStatus {
  /** Is eligible telco connection but not enabled for that visited domain. */
  NOT_ENABLED = 'notEnabled',
  /** Is not eligible telco connection. */
  NOT_FOUND = 'notFound',
  /** Is eligible telco connection. */
  OK = 'ok',
}

/**
 * The response of the utiq /op/idconnect/mno-precheck endpoint.
 */
export interface MnoResponse {
  status: MnoStatus;
}
