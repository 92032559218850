import { isString } from '@mbrtargeting/metatag-utils';

export interface CorsRequestOptions {
    method?: 'GET' | 'POST';
    src: string;
    async?: boolean;
    preflight?: boolean;
    body?: string | Blob | FormData | null;
    accept?: string;
    contentType?: string;
    withCredentials?: boolean;
    ignoreStatus?: boolean;
    responseType?: XMLHttpRequestResponseType;
}

export interface CorsRequestWithCallbackOptions<DATA> extends CorsRequestOptions {
    onload?: (data: DATA, statusCode: number) => void;
    onerror?: () => void;
}

export const sendCorsRequest = <DATA>(options: CorsRequestWithCallbackOptions<DATA>) => {
    const {
        method = 'GET', src, async = true, preflight = false, body, accept, contentType, withCredentials = false, ignoreStatus = false, responseType = 'json', onload = () => {
        }, onerror = () => {
        }
    } = options;
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = withCredentials;
    xhr.open(method, src, async);
    if (preflight) {
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    } else if (!contentType) {
        xhr.setRequestHeader('Content-Type', 'text/plain');
    }
    if (contentType) xhr.setRequestHeader('Content-Type', contentType);
    if (accept) xhr.setRequestHeader('Accept', accept);
    xhr.responseType = responseType;
    xhr.onerror = onerror;
    xhr.onload = () => {
        if (xhr.readyState === 4) {
            if (xhr.status >= 200 && xhr.status < 400 || ignoreStatus) {
                let response = xhr.response;
                if (isString(response) && responseType === 'json') { // IE11 fix
                    try {
                        response = JSON.parse(response);
                    } catch (err) {
                        return onerror();
                    }
                }
                return onload(response, xhr.status);
            } else {
                return onerror();
            }
        }
    };
    xhr.send(body);
};

export const sendCorsRequestPromise = <DATA>(options: CorsRequestOptions): Promise<DATA> => new Promise((resolve, reject) => {
    sendCorsRequest({
        ...options,
        onload: (data: DATA) => resolve(data),
        onerror: () => reject(new Error('error loading json')),
    });
});
