import { log } from '../loader/essentials/logger.js';
import { measurePerformance } from '../utils/performance.js';

/**
 * A listener function to notify when a transition between two states is made.
 */
export type TransitionListener = <STATE, ACTION>(from: STATE, to: STATE, action: ACTION) => Promise<void>;

/**
 * Describes a movement between two states of a `StateMachine`.
 */
export interface Transition<STATE, ACTION> {
    /** the source of the transition */
    from: STATE;
    /** the target of the transition */
    to: STATE;
    /** a named activity from one to another state  */
    action: ACTION;
    /** a list of listeners to invoke when the transition is executed */
    listeners?: TransitionListener[];
}

export interface DispatchOptions {
    onInvalidTransition?: TransitionListener;
}

/**
 * The options of a StateMachine when it is created.
 */
export interface StateMachineConfig<STATE, ACTION, CONTEXT> {
    /** a log prefix, e.g. name for the state machine */
    logPrefix?: string;
    /** the first state where we start */
    initialState: STATE;
    /** the initial context object */
    initialContext: CONTEXT;
    measure?: boolean;
    /** describes possible transitions between states */
    transitions?: Transition<STATE, ACTION>[];
}

export interface StateListenerOptions {
    once?: boolean;
    measure?: boolean;
    name?: string;
}

/** a global counter for id incrementing */
let nextId: number = 0;

/**
 * Describes a system of finite number of states and their transition between states.
 * When entering or leaving a state or executing a transition between states, listeners can be triggered.
 */
export class StateMachine<STATE extends string | number, ACTION extends string | number, CONTEXT extends object = {}> {

    /** a log prefix, e.g. name for the `StateMachine` */
    private logPrefix: string;
    private initialState: STATE;
    private measure: boolean;
    /** the current state of the StateMachine, using initialState from constructor initially */
    private state: STATE;
    /** all possible transitions of the `StateMachine` graph */
    private transitions: Transition<STATE, ACTION>[];

    /** a list of listeners per action, independent of transition setup */
    private actionListeners: Partial<Record<ACTION, Set<TransitionListener>>> = {};
    /** a list of listeners to call when a state is visited */
    private stateEnterListeners: Partial<Record<STATE, Set<TransitionListener>>> = {};
    /** a list of listeners to call when a state is active */
    private stateListeners: Partial<Record<STATE, Set<TransitionListener>>> = {};
    /** a list of listeners to call when a state is exited  */
    private stateLeaveListeners: Partial<Record<STATE, Set<TransitionListener>>> = {};

    #initialContext: CONTEXT;
    #context: CONTEXT;

    /** this queue helps to keep the order of operations when a state is set from within a listener */
    private queue = Promise.resolve();

    constructor({ logPrefix = `StateMachine${nextId++}`, initialState, initialContext, transitions = [], measure = false }: StateMachineConfig<STATE, ACTION, CONTEXT>) {
        this.logPrefix = logPrefix;
        this.state = this.initialState = initialState;
        this.#initialContext = initialContext;
        this.#context = { ...initialContext };
        this.transitions = transitions;
        this.measure = measure;

        if (measure) measurePerformance(`${this.logPrefix}-${initialState}`, { log: true }).start();
        log.notice(`${this.logPrefix}: State is now ${this.state}`);
    };

    public resetContext(): void {
        this.#context = { ...this.#initialContext };
    }

    public addContext(param?: Partial<CONTEXT>): this {
        Object.assign(this.#context, param);
        return this;
    }

    public get context(): CONTEXT {
        return this.#context;
    }

    /**
     * Adds a single transition to the `StateMachine` graph.
     *
     * @param transition describes a movement between states
     * @returns the `StateMachine` to chain operations
     */
    public addTransition(transition: Transition<STATE, ACTION>): this {
        this.transitions.push(transition);
        return this;
    }

    /**
     * Adds a single action listener independent of transitions.
     *
     * @param action for which action is this listener?
     * @param listener a function to call when the action is triggered
     * @param options options for the listener
     * @returns the `StateMachine` to chain operations
     */
    public onAction(action: ACTION, listener: TransitionListener, options?: StateListenerOptions): this {
        this.addListener((this.actionListeners[action] ||= new Set())!, listener, options);
        return this;
    }

    /**
     * Adds a single listener for a state visit.
     *
     * @param state for which state is this listener?
     * @param listener a function to call when the state is visited
     * @param options options for the listener
     * @returns the `StateMachine` to chain operations
     */
    public onEnter(state: STATE, listener: TransitionListener, options?: StateListenerOptions): this {
        this.addListener((this.stateEnterListeners[state] ||= new Set())!, listener, options);
        return this;
    }

    /**
     * Adds a single listener for a state.
     *
     * @param state for which state is this listener?
     * @param listener a function to call when the state is set
     * @param options options for the listener
     * @returns the `StateMachine` to chain operations
     */
    public onState(state: STATE, listener: TransitionListener, options?: StateListenerOptions): this {
        this.addListener((this.stateListeners[state] ||= new Set())!, listener, options);
        return this;
    }

    /**
     * Adds a single listener for a state exit.
     *
     * @param state for which state is this listener?
     * @param listener a function to call when the state is left
     * @param options options for the listener
     * @returns the `StateMachine` to chain operations
     */
    public onLeave(state: STATE, listener: TransitionListener, options?: StateListenerOptions): this {
        this.addListener((this.stateLeaveListeners[state] ||= new Set())!, listener, options);
        return this;
    }

    /**
     * Internal helper to create self-destructing listener.
     */
    private addListener(set: Set<TransitionListener>, listener: TransitionListener, options: StateListenerOptions = {}): void {
        const { once = false, measure = false, name = '' } = options;
        const hook: TransitionListener = async (from, to, action) => {
            const measureName = name ? `${this.logPrefix}-${from}-${name}` : `${this.logPrefix}-${from}`;
            const measurement = measure ? measurePerformance(measureName, { log: true }) : undefined;
            try {
                measurement?.start();
                await listener(from, to, action);
                measurement?.end();
            } finally {
                if (once) set.delete(hook);
            }
        };
        set.add(hook);
    }

    /**
     * Check if the current state of the `StateMachine` is one of given states.
     *
     * @param states states to check
     * @returns true if `StateMachine` is in one of the given states
     */
    public inAnyState(states: STATE[]): boolean {
        return states.includes(this.state);
    }

    /**
     * Runs an Action to trigger a transition between two states.
     *
     * @param act a named action
     * @param DispatchOptions options for dispatching
     * @returns a promise to await for a given action to be processed
     */
    public async dispatch(act: ACTION, { onInvalidTransition }: DispatchOptions = {}): Promise<void> {
        return this.queue = this.queue.then(async () => {
            const { logPrefix, measure } = this;
            // lookup a transitions by action from the old state
            const transition = this.transitions.filter(({ from }) => from === this.state).find(({ action }) => action === act);
            if (!transition) {
                onInvalidTransition ||= async () => log.warn(`${logPrefix}: No transition found for ${act} action in ${this.state} state.`);
                onInvalidTransition(this.state, undefined, act).catch(() => { /*NOOP*/ });
                return;
            }

            // helper to run listeners in parallel, but do not fail if one listener throws (caught by Promise.allSettled)
            const invokeListeners = async (listeners: Iterable<TransitionListener> = [], throwError: boolean) => {
                const isPromiseRejectedResult = (x: PromiseSettledResult<unknown>): x is PromiseRejectedResult => x.status === 'rejected';
                const result = await Promise.allSettled(Array.from(listeners).map(listener => listener(transition.from, transition.to, transition.action)));
                const issues = result.filter(isPromiseRejectedResult).map<string>(({ reason }) => reason.message);
                if (issues.length) {
                    if (throwError) throw new Error(issues.join(','));
                    log.warn(`${logPrefix}: Reject by listener`, [issues]);
                }
            };

            log.debug(`${logPrefix}: Try to switch from state ${transition.from} to state ${transition.to} via ${act} action...`);
            try {
                // run all leave listeners of the old state
                await invokeListeners(this.stateLeaveListeners[transition.from], false);

                // run all action listeners on transition or directly registered
                await invokeListeners(this.actionListeners[transition.action], true);
                await invokeListeners(transition.listeners, true);
                if (measure) measurePerformance(`${logPrefix}-${transition.from}`).end();

                // now change the state
                this.state = transition.to;
                log.debug(`${logPrefix}: State is now ${this.state} - ${this.toUML()}`);
                if (measure) measurePerformance(`${logPrefix}-${transition.to}`, { log: true }).start();
                // run all enter listeners of the new state
                await invokeListeners(this.stateEnterListeners[transition.to], false);
                // run all state listeners of the new state
                await invokeListeners(this.stateListeners[transition.to], false);
            } catch (error: any) {
                log.debug(`${logPrefix}: Error switching state ${transition.from} to state ${transition.to} via ${act} action: ${error.message}`);
            }
        });
    }

    /**
     * `StateMachine` as diagram for diagnostic purposes.
     *
     * @returns url to uml diagram
     */
    public toUML() {
        const lines = [
            '@startuml',
            'hide empty description',
            `title ${this.logPrefix}`,
            `[*] --> ${this.initialState}`,
            ...this.transitions.map(({ from, action, to }) => `${from} -> ${to}: ${action}`),
            `note top of ${this.state}: current state`,
            '@enduml',
        ];
        const toHex = (s: string) => s.split('').reduce((hex, c) => hex += c.charCodeAt(0).toString(16).padStart(2, '0'), '');
        return `https://www.planttext.com/api/plantuml/svg/~h${toHex(lines.join('\n'))}`;
    }
}
