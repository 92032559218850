import { SystemEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { isNumber, isString } from '@mbrtargeting/metatag-utils';
import { inject, injectionTarget } from '../../../loader/decorators/inject.js';
import { Phase, onPhase } from '../../../loader/decorators/on-phase.js';
import { ConfigResolver } from '../../../loader/essentials/config-resolver.js';
import { selectModuleSettings } from '../../../loader/essentials/config-selector.js';
import { triggerEvent } from '../../../loader/essentials/events.js';
import { logger } from '../../../loader/essentials/logger.js';
import { ConsentCache } from '../../../loader/modules/consent-cache.js';
import { CONFIG_RESOLVER, CONSENT_CACHE } from '../../../loader/token.js';
import { evaluateConsent } from '../../../utils/consent-helper.js';
import { tcStringInfo } from '../../../utils/tcstring-helper.js';
import { ID_STORE } from '../../token.js';
import { IdStore } from '../idstore/idstore.js';
import { NetIDStatus, ShowLayerResult, readNetIDUserStatus, sendNetIdToYieldlove, showLayer, writeNetIDPermissions } from './netid-helper.js';

const log = logger.logGroup({ prefix: 'NetId' });

const DAY_IN_MS = 864e5;

@injectionTarget()
export class NetId {

    @inject(CONFIG_RESOLVER) #configResolver!: ConfigResolver;
    @inject(ID_STORE) #idStore!: IdStore;
    @inject(CONSENT_CACHE) #consentCache!: ConsentCache;

    private static readonly ID_NAME = 'netid';

    @onPhase({ phase: Phase.TARGETING })
    async init() {
        const {
            active = false,
            tappId,
            iabVendorId = 0,
            recheckTime = DAY_IN_MS * 1,
            declineResurfaceTime = DAY_IN_MS * 14,
            denyResurfaceTime = DAY_IN_MS * 30,
            acceptResurfaceTime = DAY_IN_MS * 365,
            delayLayerTime, // DEFAULT: undefined - do not delay the layer
            ...layerOptions
        } = this.#configResolver.get(selectModuleSettings('NETID')) || {};

        if (!active) return log.debug('module not active');
        if (!tappId) return log.error('tappId is missing');

        const tcData = await this.#consentCache.getTcDataPromise();

        const allowed = evaluateConsent(tcData, [
            { consentedPurposes: [1], consentedVendors: [iabVendorId] },
            { gdprApplies: false },
        ]);

        if (!allowed) return log.debug('no consent');

        // reading the idData if present, waiting for read-sync which requires consent for storage
        const idData = await this.#idStore.getIdDataPromise(NetId.ID_NAME);

        // if a netId is present and still valid according to the timestamp, we can skip the rest
        if (idData && isString(idData?.value) && idData.ts > Date.now() - recheckTime) {
            log.debug('still valid');
            triggerEvent(SystemEvent.NETID_RESPONDED_USERVALID, {});
            triggerEvent((idData.value.length > 0) ? SystemEvent.NETID_HAS_ACCOUNT : SystemEvent.NETID_HAS_NO_ACCOUNT, {});
            sendNetIdToYieldlove({ userId: idData.value });
            return;
        }

        log.debug('need check');
        triggerEvent(SystemEvent.NETID_CHECK_USER, {});

        // calling netId endpoint to check the user status
        // this may result in 404 or 403 error by design of the netid api and is not an error
        const { status, userId, history } = await readNetIDUserStatus({ tappId });

        switch (status) {
            case NetIDStatus.ERROR:
            case NetIDStatus.UNKNOWN_USER:
                log.debug('no known user');
                // saving an empty id for the unknown user to realize a frequency capping for the netid endpoint
                this.#idStore.setIdPromise(NetId.ID_NAME, '').then(() => {
                    log.debug('saved id empty');
                });
                triggerEvent(SystemEvent.NETID_HAS_NO_ACCOUNT, {});
                triggerEvent(SystemEvent.NETID_RESPONDED_USER_UNKNOWN, {});
                break; // case NetIDStatus.UNKNOWN_USER

            case NetIDStatus.NETID_USER:
                triggerEvent(SystemEvent.NETID_HAS_ACCOUNT, {});

                // if we get the userId her, we have consent to use it directly
                if (userId) {
                    log.debug('found user (with consent to use the id)', [userId]);
                    this.#idStore.setIdPromise(NetId.ID_NAME, userId).then(() => {
                        log.debug('saved id "%s"', [userId]);
                    });
                    triggerEvent(SystemEvent.NETID_RESPONDED_USER_KNOWN, {});
                    sendNetIdToYieldlove({ userId });
                    return;
                }

                // as the userId is empty, we need to prompt for consent
                // fequency cap the layer if the user recently declined (close button)
                const recentlyDeclined = idData?.attr && isNumber(idData.attr?.fc) && (idData.attr.fc > Date.now() - declineResurfaceTime);
                if (recentlyDeclined) {
                    log.debug('found user, but recently declined - do not show layer');
                    return;
                }

                // as the userId is empty, we need to prompt for consent
                // fequency cap the layer if the user recently denied (deny button)
                const recentlyDenied = history?.some(({ type, status, changed_at }) => type === 'IDCONSENT' && status === 'INVALID' && Date.parse(changed_at) > (Date.now() - denyResurfaceTime));
                if (recentlyDenied) {
                    log.debug('found user, but recently denied - do not show layer');
                    this.#idStore.setIdPromise(NetId.ID_NAME, '').then(() => {
                        log.debug('saved id empty');
                    });
                    return;
                }

                // check if the user had recently a cmp consent layer; we should have some time before showing the netid layer
                if (delayLayerTime) {
                    const { lastUpdated = new Date() } = tcData && tcStringInfo(tcData.tcString) || {};
                    if ((Date.now() - lastUpdated.getTime()) < delayLayerTime) {
                        log.debug('we recently had an consent layer - do not show layer');
                        return;
                    }
                }

                // as the userId is empty, we need to prompt for consent
                // show the layer asynchronously; this happens outside the targeting phase
                log.debug('found user, but we need to prompt for consent');
                triggerEvent(SystemEvent.NETID_SHOW_LAYER, {});
                showLayer(layerOptions).then(async layerResult => {
                    switch (layerResult) {
                        case ShowLayerResult.DECLINE:
                            log.debug('user declined the use of the id, closing consent layer');
                            // saving an empty id for the declined layer (close button) to realize a frequency capping for the netid endpoint
                            // an additional timestamp "fc" is stored to frequency cap the consent layer
                            this.#idStore.setIdPromise(NetId.ID_NAME, '', { fc: Date.now() }).then(() => {
                                log.debug('saved id empty');
                            });
                            triggerEvent(SystemEvent.NETID_RESPONDED_NEGATIVE, {});
                            break; // case LayerResult.DECLINE

                        case ShowLayerResult.DENY:
                            log.debug('user denied the use of the id, writing negative consent to netID');

                            // store the explicit deny in the netid privacy center
                            await writeNetIDPermissions({ tappId, consent: false });

                            // saving an empty id for the denied layer (deny button) to realize a frequency capping for the netid endpoint
                            // the frequency cap of the consent layer is done via history provided by the read endpoint
                            this.#idStore.setIdPromise(NetId.ID_NAME, '').then(() => {
                                log.debug('saved id empty');
                            });
                            triggerEvent(SystemEvent.NETID_RESPONDED_NEGATIVE, {});
                            break; // case LayerResult.DENY

                        case ShowLayerResult.ACCEPT:
                            log.debug('user accepted the use of the id, writing consent to netID');

                            // store the explicit accept in the netid privacy center
                            // the response of this call returns the userId
                            const { status, userId = '' } = await writeNetIDPermissions({ tappId, consent: true });

                            switch (status) {
                                case NetIDStatus.ERROR:
                                case NetIDStatus.UNKNOWN_USER:
                                    log.error('error writing positive consent to netID');
                                    // something went totally wrong, store an empty id to realize a frequency capping for the netid endpoint
                                    this.#idStore.setIdPromise(NetId.ID_NAME, '').then(() => {
                                        log.debug('saved id empty');
                                    });
                                    triggerEvent(SystemEvent.NETID_RESPONDED_ERROR, {});
                                    break; // NetIDStatus.UNKNOWN_USER

                                case NetIDStatus.NETID_USER:
                                    log.debug('successfully wrote positive consent to netID');
                                    // we got the userId, store and use it
                                    this.#idStore.setIdPromise(NetId.ID_NAME, userId).then(() => {
                                        log.debug('saved id "%s"', [userId]);
                                    });
                                    triggerEvent(SystemEvent.NETID_RESPONDED_POSITIVE, {});
                                    sendNetIdToYieldlove({ userId });
                                    break; // case NetIDStatus.NETID_USER
                            }
                            break; // case LayerResult.ACCEPT
                    }
                });
                break; // case NetIDStatus.NETID_USER
        }
    }
}
