import { SystemEvent } from '@mbrtargeting/metatag-shared-types';
import { debounce } from '@mbrtargeting/metatag-utils';
import { inject, injectionTarget } from '../../../loader/decorators/inject.js';
import { ConfigResolver } from '../../../loader/essentials/config-resolver.js';
import { selectModuleSettings } from '../../../loader/essentials/config-selector.js';
import { CONFIG_RESOLVER } from '../../../loader/token.js';
import { sessionId$ } from '../../observables/session-id.js';

@injectionTarget()
export class UtiqTracking {

    @inject(CONFIG_RESOLVER) #configResolver!: ConfigResolver;

    #bitMask: number = 0;

    #bitMap = {
        [SystemEvent.UTIQ_ID_PRESENT]: 1, // 0000000001
        [SystemEvent.UTIQ_ID_ABSENT]: 2, // 0000000010
        [SystemEvent.UTIQ_USER_NOT_ELIGIBLE]: 4, // 0000000100
        [SystemEvent.UTIQ_USER_ELIGIBLE]: 8, // 0000001000
        [SystemEvent.UTIQ_USER_ELIGIBLE_NOT_DOMAIN]: 16, // 0000010000
        [SystemEvent.UTIQ_CONSENT_TRUE]: 32, // 0000100000
        [SystemEvent.UTIQ_CONSENT_FALSE]: 64, // 0001000000
        [SystemEvent.UTIQ_LAYER_SHOW]: 128, // 0010000000
        [SystemEvent.UTIQ_LAYER_ACCEPT]: 256, // 0100000000
        [SystemEvent.UTIQ_LAYER_REJECT]: 512, // 1000000000
    };

    constructor() {
        const sendDebounced = debounce(() => this.send(), 500);

        for (const [type, value] of Object.entries(this.#bitMap)) {
            window.addEventListener(type, () => {
                this.#bitMask |= value;
                sendDebounced()
            });
        }
    }

    private send() {
        try {
            const { enableBeacons = false } = this.#configResolver.get(selectModuleSettings('UTIQ')) || {};
            enableBeacons && window.navigator.sendBeacon(`https://ih.adscale.de/utiq?utiqEvent=${this.#bitMask}&groupId=${sessionId$.value}`);
        } catch (e) {
            // ignore
        }
    }
}
