import { Phase } from '../../interfaces/constants.js';

export { Phase } from '../../interfaces/constants.js';

(Symbol as any).metadata ||= Symbol('Symbol.metadata');

const OnPhaseSymbol = Symbol('Symbol.sdgOnPhase');

export interface PhaseMetadata {
    phase: Phase,
    description?: string;
}

export const onPhase = (data: PhaseMetadata) => {
    return function decorator(_target: any, context: ClassMethodDecoratorContext) {
        const metadata = (context.metadata[OnPhaseSymbol] ||= {}) as Record<string | symbol, PhaseMetadata[]>;
        (metadata[context.name] ||= []).push(data);
    }
}

export const getAllPhaseMetadata = (target: Object) => {
    const metadata: DecoratorMetadataObject = target.constructor[Symbol.metadata] || {};
    return (metadata[OnPhaseSymbol] || {}) as Record<string | symbol, PhaseMetadata[]>;
};
