import { SystemEvent } from '@mbrtargeting/metatag-shared-types';
import { IdStore } from '../core/modules/idstore/idstore.js';
import { NetIdTracking } from '../core/modules/netid/netid-tracking.js';
import { NetId } from '../core/modules/netid/netid.js';
import { PhaseController } from '../core/modules/phase-controller/phase-controller.js';
import { StorageHelper } from '../core/modules/storage/storage-helper.js';
import { UtiqTracking } from '../core/modules/utiq/utiq-tracking.js';
import { Utiq } from '../core/modules/utiq/utiq.js';
import { ID_STORE, NET_ID, NET_ID_TRACKING, PHASE_CONTROLLER, STORAGE_HELPER, UTIQ, UTIQ_TRACKING } from '../core/token.js';
import { triggerEvent } from '../loader/essentials/events.js';
import { MetaTag } from '../loader/essentials/init-metatag.js';
import { log } from '../loader/essentials/logger.js';
import { ConsentCache } from '../loader/modules/consent-cache.js';
import { CONSENT_CACHE } from '../loader/token.js';

declare const window: Window & {
    SDG?: MetaTag;
};

const SDG = window.SDG = new MetaTag(window.SDG?.cmd || []);
SDG.registerModule(CONSENT_CACHE, ConsentCache);
SDG.registerModule(STORAGE_HELPER, StorageHelper);
SDG.registerModule(ID_STORE, IdStore);
SDG.registerModule(NET_ID_TRACKING, NetIdTracking);
SDG.registerModule(NET_ID, NetId);
SDG.registerModule(UTIQ_TRACKING, UtiqTracking);
SDG.registerModule(UTIQ, Utiq);
SDG.registerModule(PHASE_CONTROLLER, PhaseController);

// TODO
// @ts-ignore
SDG.configure({
    modules: [{
        // @ts-ignore
        UTIQ: {
            active: true,
            iabVendorId: 136,
            customUtiqHost: 'https://utiq.duden.de',
            customizationOptions: {
                buttons: {
                    bodyColor: '#333333',
                    textColor: '#ffffff',
                    radius: 0,
                },
                hyperlinks: {
                    color: '#333333',
                    colorOnHover: '#333333',
                    isUnderlined: false,
                },
                contentTextColor: '#333333',
                websiteLogoUrl: 'https://www.duden.de/sites/default/res/duden.svg',
            },
            enableBeacons: true,
            delayLayerTime: 864e5,
        }
    }]
});

log.info('MetaTag2 IDTag - Version %s', [__METATAG_VERSION__]);

triggerEvent(SystemEvent.SDG_CORE_FILE_LOADED, {});
