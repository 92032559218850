export enum Phase {
    INIT = 'INIT',
    CONFIGURED = 'CONFIGURED',
    READY = 'READY',
    CONSENT_AVAILABLE = 'CONSENT_AVAILABLE',
    TARGETING = 'TARGETING',
    DELIVERY = 'DELIVERY',
    TRACKING = 'TRACKING'
}

export enum Visibility {
    INVIEW = 'INVIEW',
    OUTVIEW = 'OUTVIEW',
}
