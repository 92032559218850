import { isArray, isNumber, isString } from '@mbrtargeting/metatag-utils';
import { inject, injectionTarget } from '../../../loader/decorators/inject.js';
import { logger } from '../../../loader/essentials/logger.js';
import { STORAGE_HELPER } from '../../token.js';
import { StorageConsent, StorageHelper, StorageType } from '../storage/storage-helper.js';
import { GetIdPromiseOptions, IdAttributes, IdData, IdStoreInterface, IdValue, IdVendor } from './idstore-interface.js';

const log = logger.logGroup({ prefix: 'IdStore' });

/** This is a comparator function used for sorting Data objects in descending order based on their timestamp (ts) property. */
const sortByTimeDesc = (data1: IdData, data2: IdData) => data2.ts - data1.ts;
/** This is a filtering function that ensures only the first record with a specific name is retained. */
const filterFirstRecordByName = (data1: IdData, index: number, arr: IdData[]): boolean => index === arr.findIndex(data2 => data2.name === data1.name);
/** This is a validation function that checks whether a data object is valid and useable. */
const isValidData = ({ name, value, ts }: IdData): boolean => isString(name) && (isString(value) || isNumber(value)) && isNumber(ts);
/** This is a validation function that checks whether an array of data objects is valid. */
const isValidDataArray = (input: IdData[] | null): input is IdData[] => isArray(input) && input.every(isValidData);
/** This is a reducer function used to transform an array of data objects into a dictionary-like structure where the keys are name (of type IdVendor) and values are value. */
const reduceIds = (result: Record<IdVendor, IdValue>, { name, value = '' }: IdData) => ({ ...result, [name]: value });

/**
 * This class is used for managing and storing data related to ID vendors and their associated values.
 */
@injectionTarget()
export class IdStore implements IdStoreInterface {

    @inject(STORAGE_HELPER) private storageHelper!: StorageHelper;

    private data: IdData[] = [];
    private sync: Promise<void>;

    private static readonly STORAGE_KEY_NAME = 'SDGIds';

    constructor() {
        this.sync = this.restore();
    }

    /**
     * @interface IdStoreInterface
     * @inheritdoc
     */
    public async setIdPromise(name: IdVendor, value: IdValue, attr?: IdAttributes): Promise<void> {
        this.data.push({ name, value, ts: Date.now(), ...attr ? { attr } : undefined });
        return this.sync = this.sync.then(() => this.save());
    }

    /**
     * @interface IdStoreInterface
     * @inheritdoc
     */
    public async deleteIdPromise(name: IdVendor): Promise<void> {
        this.data.push({ name, value: undefined, ts: Date.now() });
        return this.sync = this.sync.then(() => this.save());
    }

    public getIdData(name: IdVendor): IdData | undefined {
        return this.data
            .sort(sortByTimeDesc)
            .find(data => data.name === name);
    }

    /**
     * @interface IdStoreInterface
     * @inheritdoc
     */
    public getId(name: IdVendor): IdValue | undefined {
        return this.getIdData(name)?.value;
    }

    /**
     * @interface IdStoreInterface
     * @inheritdoc
     */
    public async getIdDataPromise(name: IdVendor, options?: GetIdPromiseOptions): Promise<IdData | undefined> {
        if (options?.waitForSync !== false) await this.sync;
        return this.getIdData(name);
    }

    /**
     * @interface IdStoreInterface
     * @inheritdoc
     */
    public getIds(): Record<IdVendor, IdValue> {
        return this.data
            .sort(sortByTimeDesc)
            .filter(filterFirstRecordByName)
            .reduce(reduceIds, {});
    }

    /**
     * @interface IdStoreInterface
     * @inheritdoc
     */
    public async getIdsPromise(options?: GetIdPromiseOptions): Promise<Record<IdVendor, IdValue>> {
        if (options?.waitForSync !== false) await this.sync;
        return this.getIds();
    }

    /**
     * Loads all exisiting ids from localstorage.
     *
     * @returns a promise which resolves when loaded from localstorage
     */
    private async restore(): Promise<void> {
        try {
            log.debug('restore start');
            const raw: string | null = await this.storageHelper.get(StorageType.LOCAL, StorageConsent.WAIT, IdStore.STORAGE_KEY_NAME);
            const parsed: IdData[] | null = isString(raw) ? JSON.parse(raw) : null;
            if (isValidDataArray(parsed)) this.data.push(...parsed);
            log.debug('restore done %o => %o', [parsed, this.data]);
        } catch (e) {
            log.error('restore error %o', [e]);
        }
    }

    /**
     * Saves all ids from memory into localstorage.
     * This operations compresses the data by storing only the latest available id per vendor.
     *
     * @returns a promise which resolves when synchronized with localstorage
     */
    private async save(): Promise<void> {
        try {
            const data: IdData[] = this.data.sort(sortByTimeDesc).filter(filterFirstRecordByName);
            log.debug('save start %o', [data]);
            await this.storageHelper.set(StorageType.LOCAL, StorageConsent.WAIT, IdStore.STORAGE_KEY_NAME, JSON.stringify(data));
            log.debug('save done');
        } catch (e) {
            log.error('save error %o', [e]);
        }
    }
}
