import { inject, injectionTarget } from '../../../loader/decorators/inject.js';
import { ConsentCache } from '../../../loader/modules/consent-cache.js';
import { CONSENT_CACHE } from '../../../loader/token.js';
import { MemoryStorage } from './memory-storage.js';
import { StorageInterface } from './storage-interface.js';

export enum StorageType {
    /** the browsers localStorage as storage layer  */
    LOCAL = 'localStorage',
    /** the browsers sessionStorage as storage layer  */
    SESSION = 'sessionStorage',
    /** a custom implementation to keep things in memory for the current page impression */
    MEMORY = 'memoryStorage',
}

export enum StorageConsent {
    /** waits for tcdata from cmp before evaluating consent */
    WAIT,
    /** do not check consent, e.g. because this was already done before */
    IGNORE,
    /** run an operation if consent is currently available or return the default value */
    IF_AVAILABLE_OR_DEFAULT,
}

@injectionTarget()
export class StorageHelper {

    @inject(CONSENT_CACHE) private consentCache!: ConsentCache;

    private storages: Record<StorageType, StorageInterface> = {
        [StorageType.LOCAL]: window.localStorage,
        [StorageType.SESSION]: window.sessionStorage,
        [StorageType.MEMORY]: new MemoryStorage(),
    };

    public async set(type: StorageType, consent: StorageConsent, key: string, value: string): Promise<void> {
        return this.preCondition(consent).then((allowed: boolean) => void (allowed && this.storages[type].setItem(key, value)));
    }

    public async get(type: StorageType, consent: StorageConsent, key: string, fallback: string | null = null): Promise<string | null> {
        return this.preCondition(consent).then((allowed: boolean) => (allowed) ? this.storages[type].getItem(key) ?? fallback : fallback);
    }

    public async delete(type: StorageType, consent: StorageConsent, key: string): Promise<void> {
        return this.preCondition(consent).then((allowed: boolean) => void (allowed && this.storages[type].removeItem(key)));
    }

    private async preCondition(consent: StorageConsent): Promise<boolean> {
        switch (consent) {
            case StorageConsent.IGNORE:
                return true;
            case StorageConsent.IF_AVAILABLE_OR_DEFAULT:
                return this.consentCache.ifConsentConditions([
                    { consentedPurposes: [1] },
                    { gdprApplies: false },
                ]);
            case StorageConsent.WAIT:
                return this.consentCache.whenConsentConditions([
                    { consentedPurposes: [1] },
                    { gdprApplies: false },
                ]);
        }
    }
}
